/* Fonts */
@font-face {
  font-family: "Averta Regular";
  src: url("../fonts/Averta/Averta-Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Averta Semibold";
  src: url("../fonts/Averta/Averta-Semibold.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Averta Bold";
  src: url("../fonts/Averta/Averta-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Averta Light";
  src: url("../fonts/Averta/Averta-Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Averta Regular Italic";
  src: url("../fonts/Averta/Averta-Regular-Italic.otf") format("opentype");
  font-weight: normal;
  font-style: italic;
}
