/* Filter block styles */
.filter-content {
  flex: 1 0;

  /* Filter header */
  .filter-header {
    padding-left: _rem(50);
    padding-right: _rem(50);
    padding-bottom: _rem(20);

    .MuiButtonBase-root {
      margin-left: rfs-value(-5px);
    }

    @include media-breakpoint-down(md) {
      padding-left: rfs-value(30px);
      padding-right: rfs-value(30px);
    }

    /* Title */
    .filter-header-title {
      padding-top: _rem(40);

      svg {
        width: _rem(18) !important;
        margin-right: _rem(6);
      }

      @include media-breakpoint-down(md) {
        padding-top: _rem(30);
      }

      .filter-counter-indicator {
        color: $color-s2z-white;
        font-weight: bold;
        background-color: $color-s2z-red;
        padding: _rem(10);
        min-width: _rem(40);
        min-height: _rem(40);
        border-radius: _rem(10);
      }
    }
  }

  .form-content {
    position: relative;
    padding: 0 rfs-value(45px) 0 rfs-value(50px);
    height: calc(100% - 100px);
    max-height: calc(100% - 100px);
    overflow-y: auto;

    form {
      max-height: calc(100vh - 230px);
    }

    @include media-breakpoint-down(md) {
      padding: 0 rfs-value(30px) 0 rfs-value(30px);

      form {
        max-height: calc(75vh - 230px);
      }
    }
  }

  //collapsable block
  .card.collapsable-block {
    border-color: $color-s2z-border;
    border-width: 1px;
    border-style: none none solid none;
    border-radius: 0;

    &:last-child {
      border-bottom-style: none;
    }

    .card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: transparent;
      border: none;
      cursor: pointer;
      padding: _rem(16) _rem(50) _rem(16) _rem(50);

      @include media-breakpoint-down(lg) {
        padding: _rem(16) _rem(30) _rem(15) _rem(30);
      }

      .card-title {
        font-size: _rem(14);
        line-height: _rem(18);
        margin-bottom: 0;
      }
    }

    .card-body {
      padding: 0 _rem(50) _rem(15) _rem(50);

      @include media-breakpoint-down(lg) {
        padding: 0 _rem(30) _rem(15) _rem(30);
      }

      .checkbox-filter-row {
        display: flex;
        padding-bottom: _rem(10);
        margin-bottom: 0;
        margin-left: _rem(-15);

        &:last-child {
          padding-bottom: 0;
        }

        .MuiCheckbox-root {
          padding: 0;
          margin-right: _rem(5);
          margin-left: _rem(11);
        }

        .MuiFormControlLabel-label {
          font-size: _rem(14);
        }
      }

      .search-filter-row {
        padding-top: _rem(1);
        padding-bottom: _rem(11);

        //Search field styles
        .MuiPaper-root {
          border-radius: _rem(4);
          padding: _rem(10);

          .MuiButtonBase-root.MuiIconButton-root {
            padding-right: 0;

            .MuiSvgIcon-root {
              font-size: _rem(12) !important;
            }
          }
        }

        .filter-tags {
          margin: 0 _rem(-8);
          padding-top: _rem(8);
        }
      }
    }

    &.expanded {
      background-color: $color-s2z-paper;
      border-bottom-style: solid;

      &.select {
        min-height: _rem(427);
      }

      &.checkboxes {
        .card-body {
          padding-left: _rem(35);
        }
      }

      &.date_select {
        min-height: _rem(100);
      }

      &.quantity_select {
        min-height: _rem(100);
        .inputs {
          margin-top: _rem(10);
          padding-bottom: _rem(20);
          .num-input {
            width: 100%;

            .MuiInputBase-input {
              box-shadow: none;
              border-radius: 4px;
            }
          }

          .between-wrapper {
            display: flex;
            .num-input + .num-input {
              margin-left: _rem(10);
            }
          }
        }
      }

      .date-selects-wrapper {
        .MuiFormControl-root + .MuiFormControl-root {
          margin-top: _rem(10);
        }
      }

      .last-days-inputs {
        .MuiFormControl-root {
          margin-bottom: 0;
        }

        .days-input {
          width: _rem(50);
          margin-right: _rem(10);
          background-color: $color-s2z-white;

          .MuiInputBase-input {
            box-shadow: none;
            border-radius: 4px;
          }
        }
      }
    }

    &.checkboxes {
      .card-body {
        padding-left: _rem(35);
      }
    }
  }
}

//Bottom actions
.filter-bottom-actions {
  flex: 0 1;
  padding: _rem(20) _rem(45) 0 _rem(10);

  .MuiButtonBase-root {
    justify-content: center;
    &.MuiButton-contained {
      width: _rem(97);
    }
    .MuiButton-label {
      font-weight: bold;
    }

    + .MuiButtonBase-root {
      margin-left: _rem(30);
    }
  }

  @include media-breakpoint-down(md) {
    padding-top: _rem(30);
    .MuiButtonBase-root {
      width: 50%;
    }
  }
}
