// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// scss-docs-start gray-color-variables
$white: #fff !default;
$gray-100: #fafafa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
) !default;
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$blue: #0d6efd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #d63384 !default;
$red: #ff0000 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #198754 !default;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  "blue": $blue,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800,
) !default;
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary: $color-s2z-primary !default;
$secondary: $gray-600 !default;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-900 !default;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "s2z-primary": $color-s2z-primary,
  "s2z-secondary": $color-s2z-secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $color-s2z-dark,
  "muted": $color-s2z-muted,
  "border": $color-s2z-border,
  "addon": $color-s2z-addon,
);

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  fg: 25px,
  "s2z-3": $spacer * 0.15,
  "s2z-8": $spacer * 0.4,
  "s2z-11": $spacer * 0.55,
  "s2z-12": $spacer * 0.6,
  "s2z-14": $spacer * 0.7,
  "s2z-15": $spacer * 0.75,
  "s2z-17": $spacer * 0.85,
  "s2z-19": $spacer * 0.95,
  "s2z-35": $spacer * 1.75,
  "s2z-40": $spacer * 2,
  "s2z-50": $spacer * 2.5,
  "s2z-70": $spacer * 3.5,
  // for form-groups
) !default;

$body-bg: $color-s2z-light;
$font-size-base: 0.875rem;
$input-focus-width: 0;
$input-focus-box-shadow: none;

$card-border-width: 0;
$card-cap-bg: $color-s2z-white;

$grid-gutter-width: 1.25rem;
$gutters: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  "s2z-26": $spacer * 1.3,
);

//Toast
$toast-max-width: 460px;
$toast-padding-x: 1.25rem;
$toast-padding-y: 1.25rem;
$toast-color: $color-s2z-dark;
$toast-background-color: $color-s2z-white;
$toast-border-width: 0;
$toast-border-radius: 0.625rem;
$toast-box-shadow: 0px 5px 30px 0px #0000001a;
$toast-spacing: 1.25rem;
$toast-header-color: unset;
$toast-header-background-color: "none";
$toast-font-size: 0.875rem;

@import "~bootstrap/scss/bootstrap";
