// Dropzone styles
.dropzone {
  margin-bottom: 0;
  .dropzone-field {
    padding: rfs-value(45px) rfs-value(10px);
    border: 1px dashed $color-s2z-muted;
    border-radius: rfs-value(8px);
    cursor: pointer;

    @include media-breakpoint-down(md) {
      padding: rfs-value(20px) rfs-value(10px);
    }

    &.compact {
      padding: rfs-value(5px);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: rfs-value(4px);
      border: 1px solid $color-s2z-border;
    }
  }

  .preview {
    height: rfs-value(200px);
    width: 100%;
    object-fit: cover;
    border-radius: rfs-value(8px);
    overflow: hidden;

    &.compact {
      object-fit: fill;
      border-radius: rfs-value(4px);
      border: 1px solid $color-s2z-dark;
    }
  }
}
