//Nav styles
.nav.nav-pills {
  display: inline-flex;
  border-radius: _rem(22);
  background-color: $color-s2z-white;
  padding: _rem(5);
  margin-bottom: _rem(20);
  margin-right: auto;

  @include media-breakpoint-down(md) {
    display: flex;
    margin-bottom: _rem(10);
    padding: 0 _rem(5) _rem(5) _rem(5);
    justify-content: center;
  }

  .nav-item {
    min-width: _rem(80);

    .nav-link {
      color: $color-s2z-placeholder;
      @include fontAvertaRegular();
      justify-content: center;
      cursor: pointer;
      padding: _rem(5) _rem(20);
      border-radius: _rem(20);
      &:hover {
        text-decoration: none !important;
      }
    }

    .nav-link.active {
      background-color: $color-s2z-secondary;
      color: $color-s2z-white;
      @include fontAvertaSemibold();
    }

    + .nav-item {
      margin-left: _rem(5);
    }
  }

  @include media-breakpoint-down(md) {
    .nav-item {
      flex: 1 0;
      margin-top: _rem(5);
      margin-right: _rem(15);
      min-width: _rem(100);

      &:last-child {
        margin-right: 0;
      }

      .nav-link {
        padding: _rem(5) _rem(10);
        font-size: _rem(12);
      }

      + .nav-item {
        margin-left: 0;
      }
    }
  }
}
