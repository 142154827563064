html,
body {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
  @include fontAvertaRegular();

  div {
    &.border-top,
    &.border-bottom {
      border-color: $color-s2z-border !important;
    }
  }

  .font-weight-bold {
    @include fontAvertaSemibold();
  }

  .text-muted {
    color: $color-s2z-muted !important;
  }
}

.font-18 {
  font-size: 18px;
}

hr {
  background-color: $color-s2z-border;
  opacity: 1;
}

p {
  margin-bottom: 0;
}

a {
  text-decoration: none !important;
  &:hover {
    text-decoration: underline !important;
  }
}

button.unstyled-button {
  padding: 0;
  border: none;
  background: none;
}

#root,
.react-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}

.card.details-card {
  border: none;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.03);
  border-radius: _rem(8);

  > .card-body {
    padding: _rem(30);
  }

  @include media-breakpoint-down(md) {
    margin-left: 0;
    margin-right: 0;

    .card-body {
      padding: _rem(16);
    }
  }

  &.notifications {
    .card-body {
      @include media-breakpoint-down(md) {
        padding: _rem(30) _rem(10);
      }
    }
  }
}

.MuiTabs-scroller {
  &::-webkit-scrollbar {
    display: none;
  }
}

.add-new-link {
  color: $color-s2z-green;
  @include fontAvertaSemibold();
  font-size: _rem(12);
  line-height: _rem(15);
  text-decoration: none;
  border: none;
  background: none;
  padding: 0;

  @include hover-focus {
    color: $color-s2z-green;
  }
}

.card-icon {
  position: relative;

  &:after {
    content: "";
    display: block;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
  }

  &.modal-icon {
    display: flex;
    padding-left: 0 !important;
    width: _rem(40);
    height: _rem(19);

    &.mastercard {
      width: _rem(25);
    }
  }
}

//Popover style
.popover.hint-popover {
  box-shadow: #{map-get($shadows, 1)};
  border-radius: _rem(4);
  background-color: $color-s2z-border;
  margin-right: _rem(6);
  z-index: 1300;

  .popover-body {
    padding: _rem(10) _rem(15);
    color: $color-s2z-muted;
  }
}

.font-weight-bolder {
  @include fontAvertaBold();
}

.font-weight-regular {
  @include fontAvertaRegular();
  font-weight: normal !important;
}

.font-italic {
  @include fontAvertaItalic();
}

.sr-only {
  display: none;
}

.cursor-pointer {
  cursor: pointer;
}

.rounded-sm {
  border-radius: _rem(4) !important;
}

b {
  @include fontAvertaSemibold();
}

.bg-graylight {
  background-color: $color-s2z-background;
}

.green-point-icon {
  &:before {
    content: "\2022";
    color: $color-s2z-green;
    font-size: _rem(18);
    line-height: 1;
  }
}
