.spacer {
  flex-grow: 1;
}

.btn-nostyle {
  border: none !important;
  background-color: #00000000 !important;
}

.btn-icon {
  color: #cccccc;

  &:hover {
    color: #aaaaaa;
  }
}

#list-container {
  padding: 2% 5%;

  #no-results {
    width: 100%;

    padding: 5% 0;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .filters-row {
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    gap: 10px;

    margin: 10px 0;

    .filter-box {
      background-color: #eeeeee;
      border-radius: 10px;
      padding: 10px;
      flex-shrink: 0;

      display: flex;
      align-items: center;

      &.allow-overflow {
        flex-direction: column;
        align-items: flex-start;
        flex-shrink: 1;
      }

      input[type="text"] {
        max-width: 100px;
        height: 100%;
        margin-right: 10px;
      }

      .filter-options {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        margin-bottom: 5px;

        > div {
          border: 1px solid #999999;
          padding: 5px 10px;
          margin-right: 10px;
          margin-top: 5px;
        }
      }

      button {
        padding: 5px;

        border: 0;
        background-color: #dddddd;

        transition: background-color 0.3s, color 0.3s;

        margin: 2px;

        &.active {
          background-color: #888888;
          color: #ffffff;
        }
      }
    }
  }
}

#user-results {
  border-top: 1px solid #cccccc;

  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;

  tr {
    width: 100%;
    padding: 5px 10px;

    color: #666666;

    > * {
      background-color: #eeeeee;
      border-top: 1px solid #cccccc;
      border-bottom: 1px solid #cccccc;
    }

    > :first-child {
      border-radius: 5px 0 0 5px;
      border-left: 1px solid #cccccc;
      padding-left: 10px;
    }

    > :last-child {
      border-radius: 0 5px 5px 0;
      border-right: 1px solid #cccccc;
    }

    .name {
      color: #000000;
      font-weight: bold;
    }

    .user-column {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .user-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }

    .name-area {
      .name {
        font-weight: bold;
      }
    }
  }
}

#pagination {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  margin-top: 5px;
}

#user-info-modal {
  h2 {
    font-weight: bold;
  }
  h3 {
    font-size: 18px;
  }
  h6 {
    font-weight: bold;
  }

  td,
  th {
    border: 1px solid #dddddd;
    padding: 5px 10px;
  }
  table {
    margin-bottom: 20px;
  }

  #modal-header {
    display: flex;
    justify-content: space-between;

    #close-btn {
      display: flex;
      align-items: flex-start;
    }
  }

  #modal-columns-container {
    border-top: 1px solid #dddddd;
    padding-top: 10px;

    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    flex-wrap: wrap;

    .intent-info {
      .small-list {
        font-size: 12px;
      }
    }
  }

  #user-modal-spinner-box {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .accordion-header h4 {
    margin: 0;
  }
}
