/* Forms styles */
.form-group {
  margin-bottom: _rem(16);

  &.radio-group {
    padding-top: _rem(14);
  }

  &.date-input,
  &.select,
  &.static {
    margin-bottom: _rem(25);
  }

  .small-label {
    display: block;
    font-size: _rem(12);
    line-height: _rem(14);
    color: $color-s2z-placeholder;
    padding-bottom: _rem(10);
    @include fontAvertaRegular();
  }

  textarea.form-control {
    padding: _rem(15);
    resize: none;
    border-radius: _rem(4);
    border-color: $color-s2z-border;
    font-size: _rem(14);
    line-height: _rem(18);
    background-color: $color-s2z-white;

    &:hover {
      box-shadow: none;
      border-color: $color-s2z-placeholder;
    }
  }

  /* MuiFormControlLabel */
  .MuiFormControlLabel-root.checkbox {
    margin-top: _rem(-5);
    margin-bottom: _rem(-5);

    .MuiTypography-root {
      font-size: _rem(14);
      line-height: _rem(18);
    }
  }

  .checkbox-inline-row {
    label.checkbox {
      min-width: _rem(70);
      margin-right: _rem(10);
    }
  }

  &.inline-row {
    margin-left: _rem(-5);
    margin-right: _rem(-5);

    .inline-item {
      margin: 0 _rem(5);
    }

    @include media-breakpoint-down(md) {
      margin-left: 0;
      margin-right: 0;

      .inline-item {
        margin: 0;
      }

      [class*="col-"] {
        + [class*="col-"] {
          margin-top: _rem(16);
        }
      }
    }
  }

  // form group error
  .error {
    color: $color-s2z-danger;
    font-size: 0.75rem;
  }

  &.row {
    &.static {
      @include media-breakpoint-down(lg) {
        margin-bottom: _rem(16);
      }
    }
    @include media-breakpoint-down(lg) {
      .col + .col {
        margin-top: _rem(16);
      }
    }
  }

  &.simple-input-field-container {
    input {
      height: _rem(35);
      font-size: _rem(14);
      border-radius: _rem(4);
      border: 1px solid $color-s2z-border;
      outline: none;
      &:focus,
      &:active {
        outline: none;
        box-shadow: none;
        border: 1px solid lighten($color-s2z-green, 0.3);
      }
    }
    label {
      font-size: _rem(12);
      color: $color-s2z-placeholder;
      margin-bottom: _rem(13);
    }
  }
}

//Underline color
.MuiTextField-root {
  .MuiInputBase-root {
    &:before {
      border-bottom-color: $color-s2z-dark !important;
    }
  }
}

// Form autocomplete component
// Used to obtain a smaller size, the same as other inputs
.autocomplete-sm {
  .MuiFormControl-root {
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"][class*="MuiOutlinedInput-marginDense"] {
      padding: _rem(5);
    }
    .MuiOutlinedInput-notchedOutline {
      height: _rem(36.62);
      top: 0;
    }
  }
}

.fix-labels {
  .form-group {
    .small-label {
      line-height: _rem(15);
      padding-bottom: _rem(10);
    }
  }
}

.static-field {
  height: _rem(35);
}
