/* DataTable styles */
table[aria-label="Data table"] {
  @media (min-width: 1200px) {
    table-layout: fixed;
  }

  tbody {
    tr {
      td {
        padding-top: 4px;
        padding-bottom: 4px;
        cursor: pointer;
        vertical-align: middle;

        span.inner {
          display: block;
          max-width: 14vw;
        }

        span.long-inner {
          display: block;
          max-width: 30vw;
        }

        .MuiSelect-root {
          padding-left: 0;
        }
      }

      &.hidden-last-cells {
        td {
          p {
            visibility: hidden;
          }
          &:first-child,
          &:nth-child(2),
          &:last-child {
            p {
              visibility: visible;
            }
          }
        }

        td:nth-child(2) {
          p,
          span {
            @include fontAvertaSemibold();
          }

          &.marked-completed {
            span,
            svg {
              display: inline-block;
            }

            @media (max-width: 1050px) {
              span {
                display: block;
              }
              svg {
                display: none;
              }
            }
          }
        }
      }

      &.no-clickable-row {
        td {
          cursor: default;
        }
      }
    }
  }

  tr {
    @include media-breakpoint-down(md) {
      th,
      td {
        max-width: 35vw;
        .MuiTypography-body2,
        h6,
        span {
          font-size: _rem(12);
          line-height: 1.2;
        }
      }
    }
  }

  @include media-breakpoint-down(xl) {
    thead,
    tbody {
      th,
      td {
        padding-left: _rem(6);
        padding-right: _rem(6);
      }
    }
  }

  @include media-breakpoint-down(lg) {
    tbody {
      td {
        span.inner {
          max-width: 8vw;
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    tbody {
      span.inner {
        white-space: normal !important;
        max-width: unset !important;
      }
    }
  }

  @include media-breakpoint-down(lg) {
    tbody {
      tr {
        &:nth-child(odd) {
          @include boxShadow(2);
          border-radius: 8px 0 0 8px;

          td {
            &:first-child {
              border-radius: 8px 0 0 8px;
            }

            &:last-child {
              border-radius: 0 8px 8px 0;
            }

            &.with-expanded-next-row {
              &:first-child {
                border-radius: 8px 0 0 0;
              }

              &:last-child {
                border-radius: 0 8px 0 0;
              }
            }
          }
        }

        &:nth-child(even) {
          td {
            padding-bottom: 8px;
          }
        }

        &.border-left {
          td:nth-child(1) {
            border-left-style: solid;
            border-left-width: _rem(5);
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
          }

          &.collapsable {
            td:nth-child(1) {
              position: relative;

              > div {
                border-bottom-left-radius: 0;
              }

              &:after {
                content: "";
                position: absolute;
                bottom: 0;
                left: _rem(-5);
                width: _rem(5);
                height: _rem(8);
                background-color: $color-s2z-background;
              }
            }
          }
        }
      }
    }
  }

  tbody.bulk-actions-block {
    flex: 1 0 100%;
    padding: _rem(21) 0;

    .elements {
      flex: 1 1;

      .control {
        flex: 1 1;

        &.actions {
          @include media-breakpoint-up(lg) {
            min-width: _rem(190);
            max-width: _rem(220);
          }
        }

        &.input {
          @include media-breakpoint-up(lg) {
            max-width: _rem(150);
          }
        }

        + .control {
          margin-top: _rem(8);
          @include media-breakpoint-up(lg) {
            margin-left: _rem(10);
            margin-top: 0;
          }
        }
      }
    }

    .action-button {
      .MuiButtonBase-root {
        @include media-breakpoint-up(lg) {
          min-width: _rem(97);
        }
      }

      @include media-breakpoint-down(lg) {
        padding-top: _rem(16);

        .MuiButtonBase-root {
          min-width: _rem(100);
        }
      }
    }

    .MuiSelect-root {
      padding-left: _rem(10);
    }

    .triggered {
      padding-top: _rem(4);
    }

    .triggered-row {
      opacity: 1;
      td {
        position: relative;

        .action-button {
          .MuiButtonBase-root {
            position: absolute;
            right: _rem(23);
            top: 50%;
            transform: translateY(-50%);
            z-index: 1000;
          }
        }
      }

      @include media-breakpoint-down(lg) {
        &:first-child {
          td {
            padding-right: 35% !important;
          }
        }
      }
    }
  }

  &.bulk-opacity {
    tr {
      opacity: 0.4;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 100;
    }
  }
}

.opacity-backdrop {
  position: fixed;
  top: 0;
  left: 15rem;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.5);

  @include media-breakpoint-down(lg) {
    top: _rem(50); //To make burger menu not frozen
    left: 0;
  }
}

.image-cell {
  .content-wrapper {
    display: flex;
    align-items: flex-start;
    padding: _rem(12) 0 _rem(10);

    picture {
      img.img-fluid {
        width: _rem(35);
        min-width: _rem(35);
        height: _rem(35);
        object-fit: cover;
        border-radius: _rem(8);

        //hide browser default box around empty img
        &:not([src]) {
          visibility: hidden;
        }
      }
    }

    .names {
      padding-left: _rem(10);

      .product-name {
        @include fontAvertaSemibold();
        line-height: _rem(18);
        margin-bottom: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        max-height: _rem(36);
      }

      .sku-number {
        font-size: _rem(12);
        line-height: _rem(15);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    @include media-breakpoint-down(md) {
      picture {
        .img-fluid {
          width: _rem(32);
          height: _rem(32);
          object-fit: cover;
        }
      }

      .names {
        .product-name {
          font-size: _rem(12);
          line-height: _rem(15);
        }

        .sku-number {
          font-size: _rem(10);
          line-height: _rem(13);
        }
      }
    }
  }
}

.min-height {
  table[aria-label="Data table"] {
    min-height: 500px;
  }
}
