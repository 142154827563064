//Toast styles
.toast-container {
  z-index: $zindex-tooltip !important;
  margin: rfs-value(10px);
  @include media-breakpoint-up(lg) {
    margin-top: rfs-value(20px);
    margin-right: rfs-value(30px);
  }
}

.toast {
  background-color: $color-s2z-white !important;
  max-width: calc(100vw - 20px);
  border-left: rfs-value(10px) solid $color-s2z-dark;

  @include media-breakpoint-down(lg) {
    border-left-width: rfs-value(5px);
    border-radius: rfs-value(5px);
  }

  // variants
  &.bg-danger {
    border-left-color: $color-s2z-danger;
  }

  &.bg-success {
    border-left-color: $color-s2z-green;
  }

  &.bg-warning {
    border-left-color: $color-s2z-warning;
  }

  &.bg-info {
    border-left-color: $color-s2z-warning;
  }

  .toast-header {
    color: $color-s2z-dark;
    padding: $toast-padding-y $toast-padding-x 0;

    .btn-close {
      margin-left: auto;
      margin-right: -0.25em;
      background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.3803 0L14.4859 0.0469654C14.6036 0.0889589 14.7092 0.158876 14.7939 0.250713C14.8786 0.34255 14.9397 0.453564 14.972 0.574226C15.0043 0.694889 15.0069 0.821595 14.9795 0.943468C14.9521 1.06534 14.8955 1.17874 14.8146 1.27393C14.7735 1.3209 14.7295 1.36493 14.6826 1.40896L8.73088 7.36473C8.68979 7.40582 8.64284 7.44105 8.58708 7.48801L8.71914 7.62597L14.7236 13.6316C14.9555 13.8606 15.0553 14.1248 14.9672 14.4418C14.8205 14.9731 14.169 15.1756 13.7434 14.8263C13.6878 14.7785 13.6349 14.7275 13.585 14.6737C11.605 12.6933 9.62402 10.712 7.64209 8.72965C7.601 8.68856 7.56872 8.64159 7.51883 8.58289L7.37796 8.71791L1.3852 14.7118C1.23259 14.8674 1.06238 14.979 0.842273 14.9966C0.673371 15.0117 0.504227 14.9703 0.361359 14.879C0.218491 14.7876 0.109975 14.6514 0.0528256 14.4917L0 14.3567V14.0925C0.070434 13.8489 0.23478 13.6757 0.40793 13.5025L6.26863 7.64358C6.31265 7.59955 6.37428 7.5702 6.46232 7.51149C6.36841 7.43518 6.31265 7.40289 6.26863 7.35886C4.318 5.40393 2.36443 3.45096 0.40793 1.49995C0.23478 1.32677 0.070434 1.15065 0 0.909951V0.645772C0.0880425 0.317015 0.299344 0.108607 0.616297 0H0.936185C1.1739 0.0821891 1.34705 0.249503 1.5202 0.422687L7.37796 6.28453L7.51883 6.41955C7.56872 6.35791 7.60687 6.30508 7.65089 6.26105L13.538 0.369851C13.6994 0.208408 13.8638 0.0645771 14.0897 0H14.3803Z' fill='%23989898'/%3E%3C/svg%3E%0A");
      opacity: 1;
      width: rfs-value(15px);
      height: rfs-value(15px);
      &:focus {
        box-shadow: none;
      }
    }
  }

  .toast-body {
    padding: rfs-value(10px) $toast-padding-x $toast-padding-y $toast-padding-x;
  }
}
