.modal-dialog {
  &.progress-dialog {
    justify-content: center;
    .modal-content {
      display: inline-flex;
      border: none;
      width: auto;
      padding: rfs-value(40px);
    }
  }
}

.modal-backdrop {
  &.progress-backdrop {
    opacity: 0.3;
  }
}
